import { Container, Grid, styled } from '@mui/material';
import React from 'react';
import ArticleCard2 from 'components/articles/article-cards/ArticleCard2';
import Link from 'next/link';
import { useTheme } from '@emotion/react';
import { ArrowForward } from '@mui/icons-material';

import { useMediaQuery } from '@mui/material';
import TrapezoidButton from 'components/buttons/Trapezoid';


const StyledColoredLink = styled(Link)(({
	theme
}) => ({
	position: 'relative',
	display: 'block',
	padding: '0.3rem 0rem',
	color: 'black',
	cursor: 'pointer',
	color: '#fd5b72',
	fontWeight: 800,
	fontsize: 20,
	borderRadius: 4,
}));

const StyledHeader = styled('h1')(({
	theme
}) => ({
	color: theme.palette.primary[100],
	fontSize: '40px',
	paddingTop: '70px'
}));

const Section1 = ({ data, error, content }) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	if (!data) {
		return (
			<Container sx={{
				mb: '4rem'
			}}>
				<StyledHeader style={{ fontSize: "46px" }}>
					{content.title.value || 'Wat is deCheckers?'}
				</StyledHeader>
				<Grid container spacing={3}>
					<Grid item md={3} xs={12} key={3}>
						<ArticleCard2 loading={true} article={null} />
					</Grid>
					<Grid item md={3} xs={12} key={4}>
						<ArticleCard2 loading={true} article={null} />
					</Grid>
					<Grid item md={3} xs={12} key={5}>
						<ArticleCard2 loading={true} colored={true} article={null} />
					</Grid>
					<Grid item md={3} xs={12} key={6}>
						<ArticleCard2 loading={true} article={null} />
					</Grid>
				</Grid>
			</Container>
		);
	}

	return (
		<Container sx={{
			mb: '4rem',
		}}>
			<StyledHeader style={{ fontSize: "40px" }} sx={{
				[theme.breakpoints.up('md')]: {
					fontSize: "40px",
				},
			}}>
				{content.title.value || 'Wat is deCheckers?'}
			</StyledHeader>
			<h2 style={{	color: '#171554'}}>Onze missie is de samenleving weerbaar maken tegen desinformatie.</h2>
			<p style={{ marginBottom: '2vh', fontSize: 19 }}>
			deCheckers wil mensen helpen in de digitale wereld hun weg te vinden tussen feit en fictie of tussen bewijs en gerucht door kwalitatieve factchecks en tips te bundelen en ontsluiten.
			</p>

			<StyledColoredLink style={{ textDecoration: 'underline', display: 'flex', alignItems: 'center' }} href={`/over-ons`}>
				Ontdek hier alles over deCheckers <ArrowForward sx={{ color: '#fd5b72', marginLeft: '5px' }} />
			</StyledColoredLink>


			<StyledHeader mt={20}>Recentste factchecks</StyledHeader>
			<Grid container spacing={5}>
				{data.map((article, index) => (
					<Grid item key={index} md={4} xs={12} sx={{ justifyContent: "center" }}>
						<ArticleCard2 article={article} priorityImage={true} />
					</Grid>
				))}
			</Grid>
			<Grid
				container
				spacing={0}
				direction="column"
				alignItems="center"
				justifyContent="center"
			>
				<StyledColoredLink style={{ fontSize: 18, fontWeight: 600, textDecoration: 'underline', display: 'flex', alignItems: 'center', marginTop: 40 }} href={`/factchecks`}>
					Alle checks <ArrowForward style={{ marginLeft: 3, height: 19 }}></ArrowForward>
				</StyledColoredLink>
			</Grid>
			<Grid
				mt={3}
				container
				spacing={0}
				direction="column"
				alignItems="center"
				justifyContent="center"
			>

				<Grid item xs={3}>
					<Link href="/factcheck-suggereren">
						<TrapezoidButton>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div style={{ flex: 1 }}>
										<div style={{ color: 'white', fontSize: isMobile ? 12 : 19, fontWeight: 600 }}>Tip ons! Suggereer zelf een factcheck</div>
									</div>
									<div>
										<ArrowForward sx={{ color: '#fd5b72', marginLeft: 1, marginTop: 1 }} />
									</div>
								</div>
							</div>
						</TrapezoidButton>
					</Link>
				</Grid>

			</Grid >
		</Container >
	);

};


export default Section1;
