import { Container, Grid, Paper, useMediaQuery } from "@mui/material";
import React from "react";
import TrapezoidButton from "components/buttons/Trapezoid";
import { ArrowForward, QuestionMark, WhatsApp } from "@mui/icons-material";
import { useTheme } from "@emotion/react";

const FactcheckQuizBanner = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <a
        href="https://api.whatsapp.com/send/?phone=32497855255&text=s%EF%BB%BFtart+quiz&type=phone_number&app_absent=0"
        target="_blank"
      >
        <Container
          sx={{
            mt: 5,
          }}
        >
          <Paper
            sx={{
              backgroundColor: "#28d797",
              width: "100%",
              maxHeight: {
                md: 260,
                xs: "fit-content",
              },
              overflow: "hidden",
              position: "relative", // Added to position WhatsApp icon
            }}
          >
            {!isMobile && (
              <WhatsApp
                sx={{
                  position: "absolute",
                  top: 10,
                  right: 30,
                  color: theme.palette.secondary[100],
                  fontSize: 75,
                }}
              />
            )}

            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                <p
                  style={{
                    marginLeft: 20,
                    color: "white",
                    fontSize: 24,
                    textAlign: "left",
                    fontWeight: 600,
                    marginRight: 20,
                    marginBottom: 0,
                  }}
                >
                  Is het echt of AI
                  <QuestionMark
                    sx={{
                      color: theme.palette.secondary[100],
                      fontSize: 50,
                      transform: "rotate(7deg)",
                      stroke: "#fd5b72",
                      strokeWidth: 1,
                      verticalAlign: "middle",
                    }}
                  />
                  🤔
                </p>
                <p
                  style={{
                    marginLeft: 20,
                    color: "white",
                    fontSize: 20,
                    textAlign: "left",
                    fontWeight: 400,
                    marginRight: 20,
                    marginTop: 5,
                  }}
                >
                  Doe de AI-quiz en test jezelf! Ga naar WhatsApp, typ “quiz” en
                  kijk of jij feit van fictie kan onderscheiden.
                  {/* print the active device size */}
                </p>
                <p style={{ marginLeft: 20, color: "white" }}>
                  Deze quiz kan je alleen spelen op je telefoon (en dus niet op
                  je computer)
                </p>
                <div style={{ marginLeft: 20, marginBottom: 38 }}>
                  <TrapezoidButton width={isMobile ? 220 : 400} height={40}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ flex: 1 }}>
                          <div
                            style={{
                              color: "white",
                              fontSize: isMobile ? 12 : 17,
                              fontWeight: 500,
                            }}
                          >
                            Speel de quiz
                          </div>
                        </div>
                        <div>
                          <ArrowForward
                            sx={{
                              color: "#fd5b72",
                              marginLeft: 1,
                              marginTop: 1,
                              stroke: "#fd5b72",
                              strokeWidth: 2,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </TrapezoidButton>
                </div>
              </Grid>

              {!isMobile && (
                <Grid
                  item
                  xs={12}
                  md={4}
                  style={{
                    paddingTop: 0,
                    display: { xs: "none", md: "block" },
                  }}
                >
                  <img
                    src="/assets/images/phone-wa-2.png"
                    style={{
                      width: "240px",
                      transform: "rotate(16deg)",
                      position: "relative",
                      top: "15%",
                      right: "5%",
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </Paper>
        </Container>
      </a>
    </>
  );
};

export default FactcheckQuizBanner;
